<template>
  <!-- <CBox bg="bgMain" height="100vh">
    <CBox ml="60px" mr="60px" pt="5px" pl="10px" pr="10px" pb="5px"> -->
      <!-- <CHeading color="fontMain" size="2xl">Dashboard Premium</CHeading>
      <CDivider borderColor="dividerColor" /> -->
  <CBox>
      <HeaderPremium label="Pesan Pengunjung" />
      <Breadcrumb />
        <!-- <CStack> -->
        <CBox w="100%" bg="white" border="1px" borderColor="borderBox" mt="20px" minH="400px">
          <CBox mx="50px" mt="20px" mb="30px">
            <CText fontWeight="bolder" color="fontMain">Pengirim</CText>
            <CBox d="flex">
              <CText color="fontInformation">Wahid Prima (wahidprima@gmail.com)</CText>
              <CBox d="flex" bg="bgSocialBadge" borders="1px" borderRadius="25px" w="120px" h="25px" ml="10px" mr="10px" class="pointer" @click="setClipboard('https://gethired.id/')">
                <CBox mx="auto" h="inherit">
                  <CStack d="table-cell" direction="row" h="inherit" verticalAlign="middle">
                    <CBox d="table-cell" verticalAlign="middle" h="inherit">
                      <CImage :src="require('@/assets/icon/copy-gray.svg')" w="15px" h="15px" />
                    </CBox>
                    <CBox d="table-cell" verticalAlign="middle" h="inherit" pl="10px">
                      <CText color="fontCopyEmail" fontSize="12px">Copy Email</CText>
                    </CBox>
                  </CStack>
                </CBox>
              </CBox>
            </CBox>
          </CBox>
          <CDivider borderColor="borderBox" border="1.5px" />
          <CBox mx="50px" my="20px">
            <CText color="fontInformation">Senin, 12 Juli 2021  .  19:43</CText>
            <CText color="fontMain" mt="10px">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
            </CText>
          </CBox>
        </CBox>
        <CBox d="flex" justifyContent="space-between" mt="20px">
          <ButtonSecondary3 label="Sebelumnya" iconPosition="left" icon="chevron-left" />
          <ButtonBlue label="Selanjutnya" iconPosition="right" icon="chevron-right" />
        </CBox>
        <!-- </CStack> -->
    <!-- </CBox> -->
  </CBox>
</template>

<script>
import { GET_USER } from "@/store/auth.module"
import Breadcrumb from "@/components/breadcrumb/Breadcrumb.vue"
import HeaderPremium from "@/components/header/HeaderPremium.vue"
import ButtonBlue from "@/components/button/ButtonBlue.vue"
import ButtonSecondary3 from "@/components/button/ButtonSecondary3.vue"

export default {
  components: {
    Breadcrumb,
    HeaderPremium,
    ButtonBlue,
    ButtonSecondary3
  },
  data() {
    return {
      list: [
        { no: 1, name: "Wahid Prima", email: "wahidprima@gmail.com", waktu: "12 Juni 2021, 19:43" },
        { no: 2, name: "Wahid Prima", email: "wahidprima@gmail.com", waktu: "12 Juni 2021, 19:43" },
        { no: 3, name: "Wahid Prima", email: "wahidprima@gmail.com", waktu: "12 Juni 2021, 19:43" },
        { no: 4, name: "Wahid Prima", email: "wahidprima@gmail.com", waktu: "12 Juni 2021, 19:43" },
        { no: 5, name: "Wahid Prima", email: "wahidprima@gmail.com", waktu: "12 Juni 2021, 19:43" },
        { no: 6, name: "Wahid Prima", email: "wahidprima@gmail.com", waktu: "12 Juni 2021, 19:43" },
        { no: 7, name: "Wahid Prima", email: "wahidprima@gmail.com", waktu: "12 Juni 2021, 19:43" },
        { no: 8, name: "Wahid Prima", email: "wahidprima@gmail.com", waktu: "12 Juni 2021, 19:43" },
        { no: 9, name: "Wahid Prima", email: "wahidprima@gmail.com", waktu: "12 Juni 2021, 19:43" },
        { no: 10, name: "Wahid Prima", email: "wahidprima@gmail.com", waktu: "12 Juni 2021, 19:43" },
      ]
    }
  },
  mounted() {
    this.getData()
  },
  computed: {
    currentUser() {
      const user = this.$store.getters.currentUser;
      return user;
    },
  },
  methods: {
    getData() {
      setTimeout(async () => {
        this.$store.dispatch(GET_USER)
      }, 100);
    },
    setClipboard(value) {
      var tempInput = document.createElement("input");
      tempInput.style = "position: absolute; left: -1000px; top: -1000px";
      tempInput.value = value;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      this.$toast({
        title: 'Copied',
        status: 'success',
        duration: 3000,
        position: 'top',
        isClosable: false
      })
    },
  }
}
</script>

<style scoped>
.table th, .table td {
  /* font-weight: $table-head-font-weight;
  font-size: $table-head-font-size; */
  border-width: 1px;
  border-color: #c7c7c7;
  color: #888888;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}
.table th {
  background-color: #f4f4f4;
}
.pointer {
  cursor: pointer;
}
</style>
