<template>
  <div>
    <CButton v-if="!iconPosition" :size="size" :disabled="disabled" bg="bgButtonSecondary3" color="buttonSecondary3" :_hover="{ bg: 'bgButtonSecondary3' }">{{ label }}</CButton>
    <CButton v-if="iconPosition == 'left'" :left-icon="icon" :size="size" :disabled="disabled" bg="bgButtonSecondary3" color="buttonSecondary3" :_hover="{ bg: 'bgButtonSecondary3' }">{{ label }}</CButton>
    <CButton v-if="iconPosition == 'right'" :right-icon="icon" :size="size" :disabled="disabled" bg="bgButtonSecondary3" color="buttonSecondary3" :_hover="{ bg: 'bgButtonSecondary3' }">{{ label }}</CButton>
  </div>
</template>

<script>
import { CButton } from "@chakra-ui/vue"

export default {
  name: "ButtonSecondary",
  components: {
    CButton
  },
  props: { 
    label: {
      type: String,
      default: "Button"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "md"
    },
    icon: {
      type: String,
      default: ""
    },
    iconPosition: {
      type: String,
      default: ""
    }
  }
}

</script>